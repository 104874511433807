import { BreadcrumbProps, ProductProps } from './schemas';

export enum SchemaTypes {
    Breadcrumb = 'Breadcrumb',
    Product = 'Product',
}

export enum AvailabilityTypes {
    BackOrder = 'https://schema.org/BackOrder',
    Discontinued = 'https://schema.org/Discontinued',
    InStock = 'https://schema.org/InStock',
    InStoreOnly = 'https://schema.org/InStoreOnly',
    LimitedAvailability = 'https://schema.org/LimitedAvailability',
    OnlineOnly = 'https://schema.org/OnlineOnly',
    OutOfStock = 'https://schema.org/OutOfStock',
    PreOrder = 'https://schema.org/PreOrder',
    PreSale = 'https://schema.org/PreSale',
    SoldOut = 'https://schema.org/SoldOut',
}

interface Breadcrumb {
    type: SchemaTypes.Breadcrumb;
    props: BreadcrumbProps;
}

interface Product {
    type: SchemaTypes.Product;
    props: ProductProps;
}

export type Schemas = Breadcrumb | Product; // | OtherSchemas
