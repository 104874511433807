import { ActiveCampaignScript, CookieConsentScript, SmartSuppScript } from '~/shared/scripts';

export default function DynamicScripts({ hideSmartSupp = false }: { hideSmartSupp?: boolean }) {
    return (
        <>
            <SmartSuppScript hide={hideSmartSupp} />
            <CookieConsentScript />
            <ActiveCampaignScript />
        </>
    );
}
