import Head from 'next/head';
import { useMemo } from 'react';
import { BreadcrumbProps, breadcrumbSchema, ProductProps, productSchema } from './schemas';
import { Schemas, SchemaTypes } from './types';

export function Schema(schema: Schemas) {
    const json = useMemo(() => {
        const data = getSchema(schema);
        return data ? JSON.stringify(data) : undefined;
    }, [schema]);

    if (json) {
        return (
            <Head>
                <script type="application/ld+json" dangerouslySetInnerHTML={{ __html: json }} />
            </Head>
        );
    }

    return null;
}

function getSchema(schema: Schemas) {
    const schemas = {
        [SchemaTypes.Breadcrumb]: breadcrumbSchema(schema.props as BreadcrumbProps),
        [SchemaTypes.Product]: productSchema(schema.props as ProductProps),
    };

    return schemas[schema.type];
}
