import { Product, WithContext } from 'schema-dts';
import { ShopTypes } from '~/lib/data-contract';
import { capitalizeWholeWords } from '~/shared/utils/helpers';
import { AvailabilityTypes } from '../types';

export function productSchema(props: ProductProps): WithContext<Product> | undefined {
    const info = props.product?.item || props.product;
    const name1 = info?.name ? info?.name + ' ' : '';
    const name2 = info?.name2 ? info?.name2 : '';
    const name = (name1 + name2).trim();

    const shortDescription = props.product?.shortDescription
        ? props.product?.shortDescription + '\r\n\r\n'
        : '';
    const longDescription = props.product?.longDescription ? props.product?.longDescription : '';
    const description = shortDescription + longDescription;

    const currentVariant = props.product?.variants?.filter(
        (variant) => variant.itemNumber === props.product?.primaryItemNumber
    )[0];

    const availability = availabilityStatus({
        stockDetails: props.stockDetails,
        variant: currentVariant,
    });

    return {
        '@context': 'https://schema.org',
        '@type': 'Product',
        name: capitalizeWholeWords(name, true),
        image: props.image?.link || '',
        description: description.trim(),
        sku:
            currentVariant?.itemNumber ||
            props.product?.item?.itemNumber ||
            props.product?.primaryItemNumber,
        color: currentVariant?.color || props.product?.item?.color,
        url: currentVariant?.itemCanonicalUrl || props.product?.item?.itemCanonicalUrl,
        gtin: currentVariant?.ean,
        brand: {
            '@type': 'Brand',
            name: props.product?.brand,
        },
        offers: {
            '@type': 'Offer',
            url: currentVariant?.itemCanonicalUrl || props.product?.item?.itemCanonicalUrl,
            priceCurrency: props.price?.currency,
            availability: availability,
            price: props.price?.price.priceInclVat,
            itemCondition: 'https://schema.org/NewCondition',
            seller: {
                '@type': 'Organization',
                name: 'Johannes Fog A/S',
            },
        },
    };
}

export default productSchema;

export interface ProductProps {
    product?: ShopTypes.ProductQueryProduct;
    price?: ShopTypes.SalesUnitPrice;
    image?: ShopTypes.ProductQueryResource;
    stockDetails?: ShopTypes.ProductStockDetailsResponse;
}

function availabilityStatus({ stockDetails, variant }: AvailabilityProps) {
    if (!stockDetails) {
        return;
    }

    switch (true) {
        case variant?.itemStatus === ShopTypes.ItemStatusValues.Discontinued:
            return AvailabilityTypes.Discontinued;
        case stockDetails?.online?.status === ShopTypes.StockStatus.LowStock:
        case stockDetails?.pickup?.status === ShopTypes.StockStatus.LowStock:
            return AvailabilityTypes.LimitedAvailability;
        case stockDetails?.online?.status === ShopTypes.StockStatus.OutOfStock &&
            stockDetails?.pickup?.status === ShopTypes.StockStatus.OutOfStock:
            return AvailabilityTypes.OutOfStock;
        case stockDetails?.online?.status === ShopTypes.StockStatus.InStock &&
            stockDetails?.pickup?.status !== ShopTypes.StockStatus.InStock &&
            stockDetails?.pickup?.status !== ShopTypes.StockStatus.LowStock:
            return AvailabilityTypes.OnlineOnly;
        case stockDetails?.online?.status !== ShopTypes.StockStatus.InStock &&
            stockDetails?.online?.status !== ShopTypes.StockStatus.LowStock &&
            stockDetails?.pickup?.status === ShopTypes.StockStatus.InStock:
            return AvailabilityTypes.InStoreOnly;
        default:
            return AvailabilityTypes.InStock;
    }
}

interface AvailabilityProps {
    stockDetails?: ShopTypes.ProductStockDetailsResponse;
    variant?: ShopTypes.ProductQueryProductItem;
}
