import React from 'react';
import { useBasket } from '~/services';
import { SkeletonLoader } from '~/shared/components/SkeletonLoader';
import { Item } from './MiniBasketItem/MiniBasketItem';
import { ItemsWrapper } from './styled';
import { useSession } from 'next-auth/react';

export const Items = () => {
    const { status } = useSession();

    const { data: basketContexts } = useBasket((basket) => basket?.contexts);

    const basketItems = basketContexts?.flatMap((context) =>
        context.freights?.flatMap((freight) =>
            freight.brands?.flatMap((brand) =>
                brand.items?.map((item) => ({ ...item, brandName: brand.name || '' })),
            ),
        ),
    );
    const basketHasOnlyOneContext = (basketContexts?.length || 0) === 1;

    return (
        <ItemsWrapper>
            {basketItems?.map((item, index) =>
                item ? (
                    <Item
                        item={item}
                        key={`minibasket-${item.itemNumber}${index}`}
                        brand={item.brandName}
                        shouldDisplayImage={basketHasOnlyOneContext}
                        shouldDisplayPrice={status === 'unauthenticated'}
                    />
                ) : null,
            ) || <SkeletonLoader isFullWidth height={110} />}
        </ItemsWrapper>
    );
};
